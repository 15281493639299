.employee {
  border: 1px solid black;
  border-radius: 5px;
  flex: 0 1 49%;
  display: flex;
  height: 35px;
  align-items: center;
  font-size: 18px;
  padding: 5px 10px;
  margin-top: 5px;
}

.employee input {
  height: 100%;
  margin: auto 20px auto 0;
}

.employeeName {
  flex: 1 1 auto;
}

.countShifts {
  color: seagreen;
  text-align: center;
  flex: 0 0 20px;
}