.month {
    font-size: 18px;
    font-weight: 500;
    padding: 5px 0;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
}

.month button {
    background-color: rgb(203, 240, 255);
    border: 2px solid #0175a8ff;
    border-radius: 4px;
    display: flex;
    margin: 0 20px;
}

.month button span {
    color: #0175a8ff;
}

.month button:hover {
    cursor: pointer;
}

.month .monthName {
    display: inline-block;
    line-height: 100%;
    max-width: 20vw;
    min-width: 5vw;
    text-align: center;
}