.day {
    background-color: rgb(203, 240, 255);
    border: 2px solid transparent;
    padding: 0 1px;
    flex: 0 1 auto;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    overflow: hidden;
}

