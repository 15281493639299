.shift,
.shift_moderable {
    background-color: cornflowerblue;
    border-radius: 5px;
    border: 1px solid blue;
    color: #fff;
    font-size: 1.01em;
    font-weight: 500;
    height: calc(20% + 1.01px);
    line-height: 1.01em;
    margin-bottom: 2px;
    padding: 0 10px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shift_moderable:hover,
.shift_moderable .delete:hover {
    cursor: pointer;
}

.shift .delete,
.shift_moderable .delete {
    background-color: cornflowerblue;
    border: none;
    display: none;
    padding: 0;
}

.shift_moderable .delete:hover {
    display: flex;
}

.shift_moderable:hover .delete {
    color: red;
    display: flex;
    font-size: 1.1em;
    line-height: 1.1em;
}