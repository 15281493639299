.nav {
  display: inline-block;
  position: absolute;
  top: 5px;
}

.menu {
  display: flex;
  padding: 0;
  margin: 0;
}

.menu li {
  list-style-type: none;
  margin-left: 30px;
  width: 200px;
}

nav ul li a {
  background: -moz-linear-gradient(45deg, rgb(100, 149, 237), rgb(136, 177, 255), rgb(100, 149, 237));
  background: -webkit-linear-gradient(45deg, rgb(100, 149, 237), rgb(136, 177, 255), rgb(100, 149, 237));
  background: linear-gradient(45deg, rgb(100, 149, 237), rgb(136, 177, 255), rgb(100, 149, 237));
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 15px;
  text-decoration: none;
  text-transform: uppercase;
}

nav ul li a:hover {
  background: #fff;
  border: 1px inset #5a86d8;
  color: cornflowerblue;
  padding: 4px 14px;
}

.menu li:nth-child(1) {
  margin-left: 20px;
}