[data-popup="modal"] {
  background-color: rgba(0, 0, 0, 0.5);
}

.window-content {
  background-color: rgba(203, 240, 255, 0.8);
  border: 5px solid rgba(191, 233, 250, 0.5);
  border-radius: 10px;
  width: max-content;
  height: max(150px, 50vh);
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 2em 5em 2em 5em;
}

.window-content h3 {
  color: rgb(36, 36, 155);
  text-transform: uppercase;
  font-size: 2em;
  margin: 0;
}

.message {
  font-size: 18px;
  color: rgb(36, 36, 155);
}

.buttonBlock {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
}