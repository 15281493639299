.today,
.day,
.inactiveDay {
    background-color: rgb(203, 240, 255);
    padding: 0 1px;
    position: relative;
}

.inactiveDay {
    background-color: rgba(203, 240, 255, 0.5);
    color: rgba(0, 0, 0, 0.5);
}


.label {
    color: inherit;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1em;
    /* height: calc(20%); */
    padding: 4px 10px;
    margin-bottom: 2px;
    text-align: right;
}

.todayLabel {
    text-decoration: underline double red;
    display: inline-block;
    border-radius: 2px;
    box-sizing: initial;

}



