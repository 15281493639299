.adminBlock {
  display: flex;
  flex-direction: column;
  max-width: 40vw;
  margin: 0 auto;
  padding: 50px 20px;
}

.title {
  margin: 0;
  text-align: center;
}

.content {
  max-height: 100%;
  padding: 0px 10px 20px;
  margin-top: 20px;
}

.actualModerator {
  display: flex;
  margin-top: 50px;
  align-items: flex-end;
  justify-content: space-between;
}

.moderatorLabel {
  font-size: 20px;
  font-weight: 700;
}

.moderator {
  font-size: 18px;
}